import React from 'react'
import './Pricing.css'
const Pricing = () => {
  return (
    <div>
   
    </div>
  )
}

export default Pricing
