import React from 'react'
import './Portfolio.css'

const Portfolio = () => {
  return (
    <div>
     {/*  <section className="work container section" id="work">
        <h2 className="section__title">Recent Works</h2>
      </section> */}
    </div>
  )
}

export default Portfolio
